<template>
  <div>
    <!-- app bar -->
    <AppBar />
    <v-container class="px-7">
      <div class="text-center brandGrey2--text">Let’s get to know you</div>
      <v-text-field
        clearable
        label="What’s your first name?"
        color="primaryColor"
        :rules="rules.name"
        v-model="firstName"></v-text-field>
      <div class="brandGrey2--text mt-6">What do you want to work on with Luminate? Choose as many as you like:</div>
      <!-- categories grid -->
      <div v-if="categories && !isLoading">
        <v-row
          class="col-12 px-0 ma-0"
          justify="space-between"
          align="start"
          no-gutters>
          <v-col
            class="pa-2 category-container"
            cols="4"
            v-for="category in categories"
            :key="category.name"
            @click="selectCategory(category.id)">
            <div
              class="category-overlay"
              v-if="selectedCategories.includes(category.id)">
              <v-icon
                class="category-selected-icon"
                color="black"
                >mdi-check</v-icon
              >
            </div>
            <CategoryItem
              class="category-item"
              :name="category.name"
              :cover="category.coverUrl" />
          </v-col>
        </v-row>
        <div class="text-center mt-4">
          <v-btn
            width="80%"
            large
            color="primaryColor white--text"
            rounded
            depressed
            @click="submit"
            :disabled="isSubmitDisabled"
            :loading="isSubmitLoading">
            Begin
          </v-btn>
        </div>
      </div>
      <div v-if="isLoading">
        <v-progress-linear
          indeterminate
          color="primaryColor"></v-progress-linear>
      </div>
    </v-container>
  </div>
</template>

<script>
  import ChooseCategoryMixin from '@/views/auth/choose-category/ChooseCategory.mixin.vue';
  export default {
    mixins: [ChooseCategoryMixin],
  };
</script>

<style lang="css" scoped>
  .category-container {
    display: grid;
    position: relative;
    max-width: 96px;
  }
  .category-item,
  .category-overlay {
    grid-area: 1 / 1;
  }
  .category-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 80px;
    height: 80px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border-radius: 16px;
    background-color: rgb(0 0 0 / 38%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .category-selected-icon {
    background-color: rgb(255 255 255 / 38%);
    border-radius: 100%;
  }
</style>
